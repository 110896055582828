<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="4">
      <v-card class="elevation-12">
        <v-toolbar color="primary" flat>
          <v-toolbar-title>Login</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              label="Email"
              name="email"
              prepend-icon="mdi-account"
              @keydown.enter="login({ email, password })"
            />

            <v-text-field
              v-model="password"
              label="Contraseña"
              name="password"
              prepend-icon="mdi-lock"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              @click:append="showPassword = !showPassword"
              @keydown.enter="login({ email, password })"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mx-auto" color="primary" :loading="isLoading" @click="login({ email, password })">Entrar</v-btn>
          <!-- <v-btn
            class="mx-auto"
            color="secondary"
            @click="
              $store.dispatch('admins/createSuperAdmin', {
                first_name: 'David',
                last_name: 'Rebollo',
                email: 'superadmin@microwd.es',
                password: 'microwd',
                role: 'SUPERADMIN'
              })
            "
          >
            Crear superadmin
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      isLoading: false,
      showPassword: false
    };
  },
  methods: {
    resetData() {
      this.email = this.password = "";
    },
    async login() {
      this.isLoading = true;

      try {
        await this.$store.dispatch("authentication/login", {
          email: this.email,
          password: this.password
        });

        this.resetData();
      } catch (error) {
        this.$store.commit("SET_SNACKBAR", { show: true, text: error.message, color: "error" });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
